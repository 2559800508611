import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizRoutine1', 'quizRoutine2', 'quizRoutine3', 'quizRoutine4', 'quizRoutine5', 'quizRoutine6', 'quizRoutine7', 'quizRoutine8'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "creating-healthy-routines"
    }}>{`Creating healthy routines`}</h2>
    <p>{`If your teen is spending more time at home during the pandemic, the chances are their usual routine has been disrupted. Routines are important as they help your teen feel more organised, in control, safe and secure, and less stressed. This can help protect your teen’s mental health at this time.`}</p>
    <Typography variant="h4" color="primary" mdxType="Typography">
  Starting with your teen’s current routine, check whether it includes the following:
    </Typography>
    <Paper mdxType="Paper">
  <Quiz id="quizRoutine1" singleAnswer={true} question="Completing schoolwork and homework during set times, with regular breaks." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine2" singleAnswer={true} question="Meal and snack times." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine3" singleAnswer={true} question="Time for physical activity each day (aim for 60mins per day)." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine4" singleAnswer={true} question="Regular sleep routines - getting up and going to bed at roughly the same time each day (no more than 2-hours difference between weekdays and weekends)." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine5" question="Free time." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine6" question="Time outdoors." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine7" question="Quiet time." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizRoutine8" question="Time to socialise." singleAnswer={true} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <p>{`If you answered “No” to any of the above or your teen doesn’t have a regular routine, work with them to create a realistic daily schedule that allows them to do their schoolwork, while also having enough time for breaks, socialising, physical activity, downtime, and fun activities.`}</p>
    <SingleImage smallGridSize={8} gridSize={4} src="/images/m2/23-remember-to-review.png" alt="Review Reminder Post-it" mdxType="SingleImage" />
    <Typography variant="h4" mdxType="Typography">
  <b>Help them to continue their hobbies.</b>
    </Typography>
    <p>{`Where possible, try to find ways for your teen to continue with activities they enjoy. For example, help them find virtual alternatives for their hobbies, that are possible while following the local restrictions.`}</p>
    <SingleImage smallGridSize={8} gridSize={4} src="/images/m2/timetable.png" alt="Timetable" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      